import './App.css';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PreviousUpload from './components/PreviousUpload';
import AjioLabels from './components/AjioLabels';
import FlipkartLabels from './components/FlipkartLabels';
import MyntraLabels from './components/MyntraLabels';


function App() {
  return (
   

   <div className="App">
     <BrowserRouter>
     <Routes>
       <Route exact path="/" element={<Login/>}/>
       <Route exact path="/Dashboard" element={<Dashboard/>}/>
       <Route exact path="/Dashboard/PreviousUpload" element={<PreviousUpload/>}/>
       <Route exact path="/Dashboard/UploadLabels" element={<AjioLabels/>}/>
       <Route exact path="/Dashboard/Flipkart" element={<FlipkartLabels/>}/>
       <Route exact path="/Dashboard/Myntra" element={<MyntraLabels/>}/>
     </Routes>
     </BrowserRouter>
     </div>
  );
}

export default App;
