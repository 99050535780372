import React from 'react';
import person from './assets/images/user-1.jpg'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function TopNav() {
    const location = useLocation();
    const navigate = useNavigate();

    const logout = () => {
        navigate('/')
    }
  return (
    <div className='rightside'>
                <nav className='navbar navbar-expand-lg navbar-light
                 d-flex justify-content-end align-items-center gap-3 px-5'>
                    <span>{location.state.name}</span>
                    <div className='dropdown'>
                        <div href='#'><img src={person} className='rounded-circle dropdown-toggle'
                            type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                            width="35" height="35" />
                            <div className="dropdown-menu dropdown-menu-right p-0" style={{ right: 0, left: 'auto' }}>
                                <a className="dropdown-item" onClick={logout}>Log Out</a>
                            </div>
                        </div>
                    </div>
                </nav>
             </div>
  )
}

export default TopNav