import React from 'react'
import SideNav from './SideNav'
import TopNav from './TopNav'
import UploadExcel from './UploadExcel'


function MyntraLabels() {
  return (
          <div className='d-flex'>
        <div><SideNav /></div>
        <div style={{ width: '100%' }}>
          <div className='d-flex justify-content-end'>
            <TopNav />
          </div>
          <h4 className="mb-3" style={{ paddingLeft: "65px",fontWeight:'bold' }}>Myntra MRP Labels</h4>
          <UploadExcel />
        </div>

      </div>
    
  )
}

export default MyntraLabels