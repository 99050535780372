import React from 'react';
import joven from './assets/images/Joven.png';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const userData = [
    { id: 1, username: 'salamon', password: '123', name: 'Saleth Salamon' },
    { id: 2, username: 'admin', password: '123', name: 'Admin' },
    { id: 3, username: 'user3', password: 'password3', name: 'User Three' }
];

function Login() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();


    const handleSubmit = (event) => {
        event.preventDefault();

        const user = userData.find((u) => u.username === username && u.password === password);

        if (user) {
            setAuthenticated(true);
            setUser(userData);
            navigate('/Dashboard/UploadLabels', { state: { name: user.name }, });
        } else {
            alert('Invalid username or password');
        }
    };

    return (
        <div className='container-fluid loginpage d-flex justify-content-center align-items-center'>
            <div className='login'>
                <img className='mt-1' src={joven} width='110' height='90'></img>
                <form onSubmit={handleSubmit}>
                    <div className='form-group mt-4 '>
                        <label className='d-block mb-2'>Username</label>
                        <input className='input' placeholder='Username' required
                            type="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>
                    <div className='form-group mt-4'>
                        <label className='d-block mb-2'>Password</label>
                        <input className='input' placeholder='Password'
                            type="password"
                            value={password}
                            required
                            onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className='form-group mt-4 signin'>
                        <button className='btn loginbtn' type='submit'>Log In</button>
                    </div>
                </form>
            </div>
        </div>
    )

}
export default Login


