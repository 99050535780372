import React from 'react'
import UploadCSV from './UploadCSV'
import SideNav from './SideNav'
import TopNav from './TopNav'


function FlipkartLabels() {
  return (
    <div className='d-flex'>
      <div><SideNav /></div>
      <div style={{ width: '100%' }}>
        <div className='d-flex justify-content-end'>
          <TopNav />
        </div>
            <UploadCSV/>
      </div>

    </div>
  )
}

export default FlipkartLabels