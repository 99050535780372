import React from 'react'
import SideNav from './SideNav'
import TopNav from './TopNav'


function PreviousUpload() {

  return (
    <div className='d-flex'>
      <div><SideNav /></div>
      <div style={{ width: '100%' }}>
        <div className='d-flex justify-content-end'>
          <p>{}</p>
          <TopNav />
        </div>

      </div>

    </div>
  )
}

export default PreviousUpload