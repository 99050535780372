import React, { useState, useEffect } from "react";
import csvlogo from "./assets/images/csvlogo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import {MdAutorenew, MdOpenInNew, MdRestartAlt, MdUpload} from "react-icons/md";

function UploadExcel() {
  const [xlsxFile, setXlsxFile] = useState(null);
  const [showProgress, setShowProgress] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [error, setError] = useState(false);

  function handleFileChange(event) {
    const uploadedile = event.target.files[0];

    if (
      uploadedile.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setXlsxFile(uploadedile);
      setShowProgress(true);
    } else {
      toast.warning("Only XLSX files are allowed!");
    }
  }

  function handleDrop(event) {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (
      droppedFile.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setXlsxFile(droppedFile);
      setShowProgress(true);
    } else {
      toast.warning("Only XLSX files are allowed!");
    }
  }
  function handleDragOver(event) {
    event.preventDefault();
  }
  function handleRemove() {
    setXlsxFile(null);
    setShowProgress(false);
  }

  // PROCESS BAR BOTH
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 20;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
  }, []);

  const theme = createTheme({
    palette: {
      secondary: {
        main: "rgba(93, 135, 255, 0.1)",
      },
      primary: {
        main: "#5D87FF",
      },
    },
  });

  const [processFile, setProcessFile] = useState(false);

  const ProcessFile = () => {
    setProcessFile(true);
    let formData = new FormData();
    formData.append("xlsxFile", xlsxFile);

    axios
      .post("https://joven-api.midfinstudios.com/joven/api/upload/label/myntra", formData, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        setProcessFile(true);
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);
        setPdfUrl(url);
        setProcessFile(false);
        toast.success("File Generated Successfully")
      })
      .catch((error) => {
        toast.error("Unsupported Upload file")
        setProcessFile(false);
        setError(true)
      });
  };

  function handleFinalPDFDownload() {
    window.open(pdfUrl + "#zoom=500");
    const a = document.createElement("a");
    a.href = pdfUrl;
    document.body.appendChild(a);
    a.download = "Myntra_label";
    a.click();
    document.body.removeChild(a);
  }

  function resetFiles(){
    setProcessFile(false);
    setXlsxFile(null);
    setPdfUrl(null);
    setShowProgress(false);
    setError(false)
  }

  return (
    <div>
      <ToastContainer />
      <div
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        style={{
          marginLeft: "63px",
          borderRadius: "8px",
          width: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "250px",
          backgroundColor: "#f8f9fa",
          color: "#5D87FF",
        }}
      >
        {xlsxFile ? (
          <div
            className="d-flex flex-column align-items-center gap-2 px-2 py-2"
            style={{
              height: "250px",
              width: "400px",
              backgroundColor: "#f8f9fa",
              border: "1px dashed #d3d3d3",
              borderRadius: "8px",
            }}
          >
            <span
              style={{
                height: "180px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "18px",
                gap: "10px",
                paddingTop: "20px",
              }}
            >
              <img
                src={csvlogo}
                style={{ height: "60px", width: "60px" }}
              ></img>
              {xlsxFile.name} Uploaded
            </span>
            <button
              onClick={handleRemove}
              className="btn"
              style={{
                height: "40px",
                width: "100%",
                fontSize: "13px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #d3d3d3",
                borderRadius: "15px",
              }}
            >
              Remove
            </button>
          </div>
        ) : (
          <div
            className="d-flex flex-column align-items-center gap-2 px-2 py-2"
            style={{
              height: "250px",
              width: "400px",
              backgroundColor: "#f8f9fa",
              border: "1px dashed #d3d3d3",
              borderRadius: "8px",
            }}
          >
            <span
              style={{
                height: "150px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "18px",
                gap: "10px",
                paddingTop: "20px",
              }}
            >
              <img
                src={csvlogo}
                style={{ height: "60px", width: "60px" }}
              ></img>
              Drag an XLSX file here
            </span>

            <span
              className="d-flex gap-2 align-items-center"
              style={{ fontSize: "10px", height: "20px" }}
            >
              <hr style={{ width: "170px" }}></hr>
              <span>OR</span>
              <hr style={{ width: "170px" }}></hr>
            </span>

            <label
              htmlFor="xlsxFile"
              style={{
                height: "40px",
                width: "100%",
                fontSize: "13px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                justifyContent: "center",
                border: "1px solid #d3d3d3",
                borderRadius: "15px",
              }}
            >
              <MdUpload style={{fontSize:'19px'}}/>&nbsp; Click here to Upload File
            </label>
            <input
              id="xlsxFile"
              style={{ display: "none" }}
              type="file"
              onChange={handleFileChange}
              accept=".xlsx"
            />
          </div>
        )}
      </div>
    
          {xlsxFile !== null ? (
            <span
              className="btn mt-2"
              style={{ backgroundColor: "#58a453", color:"white" ,width: "400px",marginLeft:'63px' }}
            >
              File Uploaded Successfully
            </span>
          ) : (
            <></>
          )}
      
      <div className="d-flex mt-5" style={{paddingLeft: "63px"}}>
        {xlsxFile ? (
          processFile === false ? (
            <div>
              {pdfUrl === null ? (
                <div className="d-flex gap-2">
                <button className="btn btn-primary d-flex align-items-center" onClick={ProcessFile}>
                  Generate Myntra Label &nbsp;<MdAutorenew />
                </button>
                {error === true ?(<button className="btn btn-warning d-flex align-items-center" onClick={resetFiles}>
                Reset &nbsp;<MdRestartAlt />
                </button>):(<></>)}
               </div>
              ) : (
                <div className="d-flex gap-2">
                <button className="btn btn-success d-flex align-items-center" onClick={handleFinalPDFDownload}>
                  Download Label &nbsp;<MdOpenInNew />
                  </button>
                  <button className="btn btn-warning d-flex align-items-center" onClick={resetFiles}>
                  Reset &nbsp;<MdRestartAlt />
                  </button></div>
              )}
            </div>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </div>
      {processFile === true ? (
        <div className="d-flex justify-content-center">
          <ThemeProvider theme={theme}>
            <Box sx={{ width: "400px" }} className="mt-5">
              <LinearProgress
                color="primary"
                variant="indeterminate"
                value={progress}
                style={{
                  height: "37px",
                  borderRadius: "10px",
                  backgroundColor: "secondary",
                  color: "red",
                }}
              />
            </Box>
          </ThemeProvider>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default UploadExcel;
