import React from 'react';
import joven from './assets/images/Joven.png';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import ajio from './assets/images/ajio-logo.png'
import flipkart from './assets/images/flipkart-logo.png'
import myntra from './assets/images/myntra-logo.png'
import { useLocation,NavLink} from 'react-router-dom';

function SideNav() {

    const location = useLocation();

    return (
        <nav className="sidebar-nav scroll-sidebar">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="mb-1 px-1 logo-brand d-flex justify-content-center">
                    <img src={joven} width='90' height='70'/>
                </div>
                <Row className='g-0'>
                    <Nav variant="pills" className="flex-column align-items-center">
                        
                            <NavLink to="/Dashboard/UploadLabels" state={{name:location.state.name}} 
                                className='navvlink'
                                style={({ isActive }) => 
                      (isActive ? {backgroundColor: '#5D87FF',color:'white'} : {backgroundColor: 'white',color:'black'})}
                                >
                                 <span className=""><img src={ajio} width='40' height='40'/></span> AJIO Labels
                            </NavLink>
                            <NavLink to="/Dashboard/Flipkart" state={{name:location.state.name}}
                                className='navvlink'
                                style={({ isActive }) => (isActive ? {backgroundColor: '#5D87FF',color:'white'} : {backgroundColor: 'white',color:'black'})}>
                                 <span className=""><img src={flipkart} width='36' height='35'/></span>   Flipkart MRP Labels
                            </NavLink>
                            <NavLink to="/Dashboard/Myntra" state={{name:location.state.name}}
                                className='navvlink'
                                style={({ isActive }) => (isActive ? {backgroundColor: '#5D87FF',color:'white'} : {backgroundColor: 'white',color:'black'})}>
                                 <span className=""><img src={myntra} width='40' height='35'/></span> Myntra MRP Labels
                            </NavLink>
                    </Nav>
                </Row>

            </Tab.Container>
        </nav>
    )
}

export default SideNav