import React from 'react'
import UploadPDFandXLSX from './UploadPDFandXLSX'
import TopNav from './TopNav'
import SideNav from './SideNav'


function AjioLabels() {

  return (
    <div>
      <div className='d-flex'>
        <div><SideNav /></div>
        <div style={{ width: '100%' }}>
          <div className='d-flex justify-content-end'>
            <TopNav />
          </div>
          <div className=''>
          <UploadPDFandXLSX/>
          </div>
        </div>

      </div>

    </div>
  )
}

export default AjioLabels