import React, { useState, useEffect } from "react";
import csvlogo from "./assets/images/csvlogo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import pdflogo from "./assets/images/pdflogo.svg";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import {
  MdAutorenew,
  MdOpenInNew,
  MdRestartAlt,
  MdUpload,
  MdOutlineClose,
} from "react-icons/md";

function UploadPDFandXLSX() {
  const [xlfile, setXlFile] = useState(null);
  const [xlShowProgress, setXlShowProgress] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [error, setError] = useState(null);

  function XlhandleFileChange(event) {
    const uploadedile = event.target.files[0];

    if (
      uploadedile.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setXlFile(uploadedile);
      setXlShowProgress(true);
      toast.success("Excel File Uploaded Successfully");
    } else {
      toast.warning("Only XLSX files are allowed!");
    }
  }

  function XlhandleDrop(event) {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (
      droppedFile.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setXlFile(droppedFile);
      setXlShowProgress(true);
      toast.success("Excel File Uploaded Successfully");
    } else {
      toast.warning("Only XLSX files are allowed!");
    }
  }

  function XlhandleDragOver(event) {
    event.preventDefault();
  }

  function XlhandleRemove() {
    setXlFile(null);
    setXlShowProgress(false);
    setProcessFile(false);
  }

  // UPLOAD PDF

  const [pdffile, setPdfFile] = useState([]);
  const [pdfShowProgress, setPdfShowProgress] = useState(false);

  useEffect(() => {
    console.log(pdffile);
  }, [pdffile]);

  const PdfhandleFileChange = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    const pdfFiles = uploadedFiles.filter(
      (file) => file.type === "application/pdf"
    );

    if (pdfFiles.length > 0) {
      setPdfFile((prevFiles) => [...prevFiles, ...pdfFiles]);
      toast.success("PDF File(s) Uploaded Successfully");
      setPdfShowProgress(true);
    } else {
      toast.warning("No valid PDF files selected");
    }

    const fileInput = document.querySelector("#pdffile");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  function PdfhandleDrop(event) {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    const pdfFiles = droppedFiles.filter(
      (file) => file.type === "application/pdf"
    );
    if (pdfFiles.length > 0) {
      if (pdfFiles.length == 1) {
        setPdfFile([...pdffile, ...pdfFiles]);
      } else {
        setPdfFile((prevFiles) => [...prevFiles, ...pdfFiles]);
      }
      toast.success("Pdf File Uploaded Successfully");
      setPdfShowProgress(true);
    } else {
      toast.warning("Only PDF files are allowed!");
    }
  }
  function PdfhandleDragOver(event) {
    event.preventDefault();
  }
  function PdfhandleRemove() {
    setPdfFile([]);
    setPdfShowProgress(false);
    setProcessFile(false);
  }

  // PROCESS BAR BOTH
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 20;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);
  }, []);

  const theme = createTheme({
    palette: {
      secondary: {
        main: "rgba(93, 135, 255, 0.1)",
      },
      primary: {
        main: "#5D87FF",
      },
    },
  });

  // ProcessFile function
  const [processFile, setProcessFile] = useState(false);

  const ProcessFile = () => {
    setProcessFile(true);
    let formData = new FormData();
    pdffile.forEach((file) => {
      formData.append("pdfFile", file);
    });
    formData.append("xlsxFile", xlfile);

    const params = {
      param: "single",
      type: "single",
    };
    axios
      .post(
        "https://joven-api.midfinstudios.com/joven/api/upload/label/tool/ajio",
        formData,
        { responseType: "arraybuffer", params }
      )
      .then((response) => {
        setProcessFile(true);
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);
        setPdfUrl(url);
        setProcessFile(false);
        toast.success("File Generated Successfully");
      })
      .catch((error) => {
        toast.error("Unsupported Uploaded file");
        setProcessFile(false);
        setError(true);
      });
  };
  const MultipleProcessFile = () => {
    setProcessFile(true);
    let formData = new FormData();
    pdffile.forEach((file) => {
      formData.append("pdfFile", file);
    });
    formData.append("xlsxFile", xlfile);

    const params = {
      param: "multiple",
      type: "multiple",
    };

    axios
      .post(
        "https://joven-api.midfinstudios.com/joven/api/upload/label/tool/ajio",
        formData,
        { responseType: "arraybuffer", params }
      )
      .then((response) => {
        setProcessFile(false);
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);
        setPdfUrl(url);
        toast.success("File Generated Successfully");
      })
      .catch((error) => {
        toast.error("Unsupported Uploaded file");
        setProcessFile(false);
        setError(true);
      });
  };
  const AllProcessFile = () => {
    setProcessFile(true);
    let formData = new FormData();
    pdffile.forEach((file) => {
      formData.append("pdfFile", file);
    });
    formData.append("xlsxFile", xlfile);

    const params = {
      param: "all",
      type: "all",
    };
    axios
      .post(
        "https://joven-api.midfinstudios.com/joven/api/upload/label/tool/ajio",
        formData,
        { responseType: "arraybuffer", params }
      )
      .then((response) => {
        setProcessFile(true);
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);
        setPdfUrl(url);
        setProcessFile(false);
        toast.success("File Generated Successfully");
      })
      .catch((error) => {
        toast.error("Unsupported Uploaded file");
        setProcessFile(false);
        setError(true);
      });
  };

  function handleFinalPDFDownload() {
    window.open(pdfUrl);
    const a = document.createElement("a");
    a.href = pdfUrl;
    document.body.appendChild(a);
    a.download = "AJIO_Label";
    a.click();
    document.body.removeChild(a);
  }

  function resetFiles() {
    window.location.reload();
  }

  return (
    <div>
      <h4 className="mb-3" style={{ paddingLeft: "65px", fontWeight: "bold" }}>
        AJIO Labels
      </h4>
      <div className="d-flex gap-5" style={{ paddingLeft: "15px" }}>
        <ToastContainer autoClose={1000} />
        <div>
          <div
            onDragOver={XlhandleDragOver}
            onDrop={XlhandleDrop}
            style={{
              borderRadius: "8px",
              width: "400px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "250px",
              backgroundColor: "#f8f9fa",
              color: "#5D87FF",
            }}
          >
            {xlfile ? (
              <div
                className="d-flex flex-column align-items-center gap-2 px-2 py-2"
                style={{
                  height: "250px",
                  width: "400px",
                  backgroundColor: "#f8f9fa",
                  border: "1px dashed #d3d3d3",
                  borderRadius: "8px",
                }}
              >
                <span
                  style={{
                    height: "180px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "18px",
                    gap: "10px",
                    paddingTop: "20px",
                  }}
                >
                  <img
                    src={csvlogo}
                    style={{ height: "60px", width: "60px" }}
                  ></img>
                  {xlfile.name} Uploaded
                </span>
                <button
                  onClick={XlhandleRemove}
                  className="btn"
                  style={{
                    height: "40px",
                    width: "100%",
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "white",
                    justifyContent: "center",
                    border: "1px solid #d3d3d3",
                    borderRadius: "15px",
                  }}
                >
                  Remove
                </button>
              </div>
            ) : (
              <div
                className="d-flex flex-column align-items-center gap-2 px-2 py-2"
                style={{
                  height: "250px",
                  width: "400px",
                  backgroundColor: "#f8f9fa",
                  border: "1px dashed #d3d3d3",
                  borderRadius: "8px",
                }}
              >
                <span
                  style={{
                    height: "150px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "18px",
                    gap: "10px",
                    paddingTop: "20px",
                  }}
                >
                  <img
                    src={csvlogo}
                    style={{ height: "60px", width: "60px" }}
                  ></img>
                  Drag an Excel file here
                </span>

                <span
                  className="d-flex gap-2 align-items-center"
                  style={{ fontSize: "10px", height: "20px" }}
                >
                  <hr style={{ width: "170px" }}></hr>
                  <span>OR</span>
                  <hr style={{ width: "170px" }}></hr>
                </span>

                <label
                  htmlFor="xlfile"
                  style={{
                    height: "40px",
                    width: "100%",
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "white",
                    justifyContent: "center",
                    border: "1px solid #d3d3d3",
                    borderRadius: "15px",
                  }}
                >
                  <MdUpload style={{ fontSize: "19px" }} />
                  &nbsp; Click here to Upload File
                </label>
                <input
                  id="xlfile"
                  style={{ display: "none" }}
                  type="file"
                  onChange={XlhandleFileChange}
                  accept=".xlsx"
                />
              </div>
            )}
          </div>
          {/* {xlfile != null ? (
            <span
              className="btn mt-2"
              style={{ backgroundColor: "#58a453", color: "white", width: "400px" }}
            >
              File Uploaded Successfully
            </span>) : (
            <></>
          )} */}
        </div>
        <div>
          <div
            onDragOver={PdfhandleDragOver}
            onDrop={PdfhandleDrop}
            id="dropZone"
            style={{
              borderRadius: "8px",
              width: "400px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "250px",
              backgroundColor: "#f8f9fa",
              color: "#5D87FF",
            }}
          >
            <div
              className="d-flex flex-column align-items-center gap-2 px-2 py-2"
              style={{
                height: "250px",
                width: "400px",
                backgroundColor: "#f8f9fa",
                border: "1px dashed #d3d3d3",
                borderRadius: "8px",
              }}
            >
              <span
                className="scroll"
                style={{
                  height: "150px",
                  width: "100%",
                  paddingTop: "35px",
                  display: "flex",
                  flexDirection: "column",

                  fontSize: "18px",
                  gap: "10px",
                  paddingTop: "20px",
                }}
              >
                {pdffile.length > 0 ? (
                  pdffile.map((file, index) => (
                    <span
                      key={index}
                      style={{
                        fontSize: "12px",
                        width: "99%",
                        display: "flex",
                        flexDirection: "column",
                        wordWrap: "break-word",
                        display: "inline",
                        paddingBottom: "5px",
                        borderBottom: "0.5px dashed rgb(211, 211, 211)",
                      }}
                    >
                      <img
                        src={pdflogo}
                        style={{
                          height: "22px",
                          width: "22px",
                          marginRight: "5px",
                        }}
                      ></img>
                      <span style={{ display: "inline" }}>
                        {file.name}Uploaded{" "}
                        <MdOutlineClose
                          onClick={() => {
                            pdffile.splice(index);
                          }}
                          style={{
                            fontSize: "15px",
                            color: "gray",
                            border: "1px solid gray",
                            borderRadius: "50%",
                          }}
                        />
                      </span>
                    </span>
                  ))
                ) : (
                  <span style={{ marginTop: "40px", marginLeft: "70px" }}>
                    <img
                      src={pdflogo}
                      style={{
                        height: "60px",
                        width: "60px",
                        marginRight: "8px",
                      }}
                    ></img>
                    Drag an PDF file here
                  </span>
                )}
              </span>

              <span
                className="d-flex gap-2 align-items-center"
                style={{ fontSize: "10px", height: "20px" }}
              >
                <hr style={{ width: "170px" }}></hr>
                <span>OR</span>
                <hr style={{ width: "170px" }}></hr>
              </span>

              <label
                htmlFor="pdffile"
                style={{
                  height: "40px",
                  width: "100%",
                  fontSize: "13px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #d3d3d3",
                  borderRadius: "15px",
                }}
              >
                <MdUpload style={{ fontSize: "19px" }} />
                &nbsp; Click here to Upload File
              </label>
              <input
                id="pdffile"
                style={{ display: "none" }}
                type="file"
                multiple
                onChange={PdfhandleFileChange}
                accept=".pdf"
              />
            </div>
          </div>
          {/* {pdffile.length >=1 ? (
            <span
              className="btn mt-2"
              style={{ backgroundColor: "#58a453", color: "white", width: "400px" }}
            >
              File Uploaded Successfully
            </span>) : (
            <></>
          )} */}
        </div>
      </div>
      <div className="d-flex mt-5" style={{ paddingLeft: "63px" }}>
        <div>
          <div className="d-flex gap-2">
            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={ProcessFile}
            >
              Generate Single SKU Label &nbsp;
              <MdAutorenew />
            </button>
            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={MultipleProcessFile}
            >
              Generate Multiple SKU Label &nbsp;
              <MdAutorenew />
            </button>
            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={AllProcessFile}
            >
              Generate All SKU Label &nbsp;
              <MdAutorenew />
            </button>
       
          </div>
          {pdfUrl != null ? (
            <div className="d-flex pl-2 mt-4  gap-3  " >
              <button
              style={{ paddingLeft: "14px" }}
                className="btn btn-success d-flex align-items-center down"
                onClick={handleFinalPDFDownload}
              >
                Download Label &nbsp;
                <MdOpenInNew />
              </button>
              <button
                className="btn btn-warning d-flex align-items-center"
  
                onClick={resetFiles}
              >
                 &nbsp;
                <MdRestartAlt />
              </button>
            </div>
          ) : (
            ""
          )}
        </div>

        <></>

        <></>
      </div>
      {processFile === true ? (
        <div className="d-flex justify-content-center">
          <ThemeProvider theme={theme}>
            <Box sx={{ width: "400px" }} className="mt-5">
              <LinearProgress
                color="primary"
                variant="indeterminate"
                value={progress}
                style={{
                  height: "37px",
                  borderRadius: "10px",
                  backgroundColor: "secondary",
                  color: "red",
                }}
              />
            </Box>
          </ThemeProvider>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default UploadPDFandXLSX;
