import React from 'react';
import SideNav from './SideNav';
import TopNav from './TopNav';
import UploadCSV from './UploadCSV';

const Dashboard = () => {
  
    return (
        <div className='d-flex'>
            <div><SideNav /></div>
            <div style={{ width: '100%' }}>
                <div className='d-flex justify-content-end'>
                    <TopNav />
                </div>
                <div className='d-flex'>
                    <UploadCSV />
                </div>
                
            </div>

        </div>
    );
};

export default Dashboard;
